/**
* Created by tangdq on 2018/8/20.
*/
<style lang="scss" scoped>
    .audit-list {
        .audit-list-screen {
            background: #ffffff;
            /*padding: 20px 20px 20px 20px;*/
            .common-list-search {
                padding-bottom: 5px;
            }

        }

        .screen-operate-btn {
            position: absolute;
            right: 10px;
            bottom: 10px;

        }
        .audit-list-table {
            background: #fff;
            margin-top: 10px;
            /*padding: 0 20px;*/
            .not-through-popover {
                display: inline-block;
                cursor: pointer;
                .el-popover__reference {
                    color: #FF3B30;
                }
            }
        }
        .list-screen-btn {
            position: relative;
            /*height: 40px;*/
        }
        .show-new {
            font-size: 10%;
            color: #ff3b30;
            display: inline-block;
            vertical-align: middle;
            margin-top: -3px
        }
    }

    .audit-list /deep/ {
        .common-list-search {
            .list-search-before {
                width: calc(100% - 180px);
            }
            .list-search-after {
                width: 180px;
                text-align: right;
            }
        }
    }

</style>
<style lang="scss">
    .audit-list {
        .audit-list-screen {

            .el-form-item {
                margin-bottom: 10px;
                display: inline-block;
                /*float: left;*/

            }
            .radio-change-date {
                width: 280px;
            }
            .radio-change-group {
                .el-form-item__content {
                    height: 32px;
                    line-height: 32px;
                }
            }
            .toastCount {
                height: 40px;
                background: #E6F7FF;
                border: 1px solid #BAE7FF;
                border-radius: 4px;
                line-height: 40px;
                padding: 0 20px;
                font-size: 14px;
                color: rgba(0, 0, 0, 0.65);
                margin-bottom: 10px;
                i {
                    color: #319BF7;
                    padding-right: 10px
                }
                span {
                    color: #319BF7;
                    font-weight: bold;
                }
            }
            .refuseButton {
                color: #319BF7;
                border-color: #319BF7
            }
            .auditCol {
                height: 70px;
                border-bottom: 1px solid #EBEEF5;
                line-height: 70px;
                display: inline-block;
                .auditName {
                    float: left;
                    padding-top: 15px;
                    p {
                        line-height: 20px;
                        span {
                            font-size: 14px;
                            color: #000B15;
                        }
                    }

                }

            }
        }

        .screen-radio-change {
            .el-radio-button:first-child {
                .el-radio-button__inner {
                    padding: 9px 12px;
                }
            }
            .el-radio-button__inner {
                padding: 9px 10px;
            }
            .screen-contract-effective {
                .el-radio-button__inner {
                    padding: 9px 18px;
                }
            }
        }
        .table-info-operation {
            .el-button {
                padding: 0;
            }
        }
    }

</style>
<template>
    <div>
        <el-main class="com-eTrip-section">
            <div class="eTrip-section-cont audit-list">
                <div class="audit-list-screen" v-if="approvalTotalCount && auditAccountList"
                     style="margin-bottom: 10px">
                    <p class="toastCount"><i
                            class="el-icon-warning toastCount-icon"></i>以下 <span>{{approvalTotalCount}}</span> 条
                        登录帐号申请，建议您联系公司管理员尽快处理。</p>
                    <el-row>
                        <el-col class="auditCol" v-for="(item,index) in auditAccountList" :key="index"
                                v-show="index<len">
                            <div class="auditName">
                                <p><span>{{item.loginuserName}} </span><span>{{item.mobile}} </span></p>
                                <p><span>{{item.roleName + '/' + item.companyName}}</span></p>
                            </div>
                            <!--<div class="auditName">-->
                            <!--<span>{{item.companyName}}-->
                            <!--</div>-->
                            <div style="float: right">
                                <el-button class="refuseButton" @click="approvalRefuseShow(item)">拒绝</el-button>
                                <el-button type="primary" @click="approvalPassShow(item, '管理员')">通过并设为管理员
                                </el-button>
                                <el-button style="background: #00305D;color: #fff"
                                           @click="approvalPassShow(item, '操作员')">通过并设为操作员
                                </el-button>
                            </div>

                        </el-col>
                        <el-col v-if="auditAccountList.length>3">
                            <el-button type="text" style="color: #909399;" v-if="len==3"
                                       @click="len=auditAccountList.length">
                                查看全部<i class="el-icon-caret-bottom el-icon--right"></i>
                            </el-button>
                            <el-button type="text" style="color: #909399;" v-else @click="len=3">
                                收起<i class="el-icon-caret-top el-icon--right"></i>
                            </el-button>
                        </el-col>
                    </el-row>
                </div>
                <div class="audit-list-screen">
                    <com-list-search>
                        <div slot="list-search-before">
                            <div class="screen-radio-change clear">
                                <el-form :inline="true" label-position="right" size="small">
                                    <el-form-item label-width="100px" style="width: 134px">
                                        <el-input placeholder="姓名" v-model="subForm.loginName"></el-input>
                                    </el-form-item>
                                    <el-form-item class="radio-change-group" style="width: 140px">
                                        <el-input placeholder="登录帐号(手机号)" v-model="subForm.mobile"></el-input>
                                    </el-form-item>
                                    <el-form-item class="radio-change-group" style="width: 134px">
                                        <el-input placeholder="绑定机构" v-model="subForm.companyName"></el-input>
                                    </el-form-item>
                                    <el-form-item class="radio-change-group" style="width: 196px;">
                                        <el-radio-group class="screen-contract-effective"
                                                        v-model="subForm.userRelRole">
                                            <el-radio-button label="0">全部</el-radio-button>
                                            <el-radio-button label="1">管理员
                                            </el-radio-button>
                                            <el-radio-button label="2">操作员
                                            </el-radio-button>
                                        </el-radio-group>
                                    </el-form-item>
                                    <el-form-item class="radio-change-group" style="width: 278px" v-if="userType==0">
                                        <el-radio-group class="screen-contract-effective"
                                                        v-model="subForm.userType">
                                            <el-radio-button label="0">全部</el-radio-button>
                                            <el-radio-button label="1">B端买方
                                            </el-radio-button>
                                            <el-radio-button label="2">供应商
                                            </el-radio-button>
                                            <el-radio-button label="3">运营商
                                            </el-radio-button>
                                        </el-radio-group>
                                    </el-form-item>
                                </el-form>
                            </div>
                        </div>
                        <div slot="list-search-after">
                            <el-button @click="screenResetClick()">重置</el-button>
                            <el-button type="primary" @click="getList()">搜索</el-button>
                        </div>

                    </com-list-search>
                </div>

                <div class="audit-list-table el-table_border_none">
                    <el-table class="table-info"
                              border
                              style="width: 100%"
                              :height="tableH"
                              v-loading="comListTableLoading"
                              :data="loginAccountList">
                        <el-table-column
                                fixed
                                label="姓名"
                                width="110">
                            <template slot-scope="scope">
                                <p>
                                    <span>{{scope.row.loginuserName}}</span>
                                    <span v-if="scope.row.showNew" class="show-new">new</span>
                                </p>

                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="mobile"
                                label="登录帐号（手机号）"
                                min-width="150">
                        </el-table-column>
                        <el-table-column
                                label="绑定机构"
                                min-width="180"
                                prop="companyName">
                        </el-table-column>
                        <el-table-column
                                label="机构类型"
                                min-width="150">
                            <template slot-scope="scope">
                                <p v-if="scope.row.refType == 1">供应商</p>
                                <p v-if="scope.row.refType == 2">运营商</p>
                                <p v-if="scope.row.refType == 3">一级用户</p>
                                <p v-if="scope.row.refType == 4">二级用户</p>
                            </template>
                        </el-table-column>
                        <el-table-column
                                label="用户角色"
                                min-width="120"
                                prop="roleName"
                        >
                        </el-table-column>
                        <el-table-column
                                label="审核状态"
                                min-width="100">
                            <template >
                                <!--<p v-if="scope.row.approvalStatus == 2">待审核</p>-->
                                <p>已通过</p>
                                <!--<p v-if="scope.row.approvalStatus == 4">未通过</p>-->
                                <!--<el-popover class="not-through-popover" v-if="scope.row.approvalStatus == 4"-->
                                <!--placement="bottom-end"-->
                                <!--title="未通过原因"-->
                                <!--width="200"-->
                                <!--trigger="hover"-->
                                <!--popper-class="through-popover-cont"-->
                                <!--:content="scope.row.approvalDesc">-->
                                <!--<p slot="reference">未通过<i class="icon icon-not-adopt-tips"></i></p>-->
                                <!--</el-popover>-->
                            </template>
                        </el-table-column>
                        <el-table-column
                                label="操作"
                                :class-name="('table-info-operation')"
                                width="100">
                            <template slot-scope="scope">
                                <el-button @click="untiedAccountShow(scope.row)"
                                           type="text">解绑
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="table-page-number" v-if="loginAccountList && loginAccountList.length > 0">
                        <div class="block">
                            <el-pagination
                                    @current-change="handleTablePageNumberChange"
                                    :current-page.sync="subForm.currentPage"
                                    :page-size="subForm.pageSize"
                                    layout="total, prev, pager, next"
                                    :total="subForm.totalPage">
                            </el-pagination>
                        </div>
                    </div>
                </div>

            </div>
        </el-main>
    </div>


</template>

<script type="text/ecmascript-6">
    import comListSearch from '../../../components/comListSearch/index.vue'

    export default {
        name: 'loginAccountList',
        //定义模版数据
        data() {
            let userInfo = this.getUserInfo();
            let userType = 0;
            if (userInfo.userType == 1) userType = 2;
            if (userInfo.userType == 2) userType = 3;
            return {
                tableH: 'calc(100vh - 312px)',
                subForm: {
                    "companyName": "",
                    "loginName": "",
                    "mobile": "",
                    "userRelRole": "0",
                    "userType": userType,
                    pageIndex: 1,
                    pageSize: 10,
                    currentPage: 1,
                    totalPage: 0,
                },
                approvalTotalCount: 0,
                comListTableLoading: false,
                loginAccountList: [],
                auditAccountList: [],
                len: 3,
                userType: userType
            }
        },
        components: {comListSearch},
        //计算属性
        computed: {},
        activated() {
            this.getList();
        },
        //主件被加载完成
        mounted: function () {
        },
        //定义事件方法
        methods: {
            screenResetClick() {  // 重置
                this.subForm = {
                    "companyName": "",
                    "loginName": "",
                    "mobile": "",
                    "userRelRole": "0",
                    "userType": this.userType,
                    pageIndex: 1,
                    pageSize: 10,
                    currentPage: 1,
                    totalPage: 0
                }
                this.getList();
            },
            async getList() {
                let form = {
                    "companyName": this.subForm.companyName,
                    "loginName": this.subForm.loginName,
                    "mobile": this.subForm.mobile,
                    "pageIndex": this.subForm.currentPage,
                    "pageSize": 10,
                    "userRelRole": this.subForm.userRelRole,
                    "userType": this.subForm.userType,
                    source: '0'
                }

                try {
                    let res = await this.http('/galaxyLoginuserApi/doGalaxyLoginuserApiManagePageList', form, 'POST');
                    console.log(res);
                    if (res.success) {
                        this.auditAccountList = res.data.approvalLoginuserRspDtoPageList.data;
                        this.loginAccountList = res.data.loginuserManagePageDtoPageList.data;
                        this.subForm.totalPage = res.data.loginuserManagePageDtoPageList.total;
                        this.approvalTotalCount = res.data.approvalLoginuserRspDtoPageList.total;
                        let date = +new Date();
                        let userInfo = this.getUserInfo().loginuserDto;
                        // console.log(date, 11111111111111);
                        this.loginAccountList.forEach(item => {
                            item.createTime = new Date(item.createTime).getTime();
                            console.log(date - item.createTime, 10 * 60 * 1000);
                            if (date - item.createTime < 10 * 60 * 1000 && userInfo.id == item.createUser) {
                                item.showNew = true;
                            }
                        })
                        this.auditAccountList.forEach(item => {
                            switch (parseInt(item.approvalType)) {
                                case 1:
                                    item.roleName = '供应商';
                                    break;
                                case 2:
                                    item.roleName = '运营商';
                                    break;
                                case 3:
                                    item.roleName = '一级用户';
                                    break;
                                case 4:
                                    item.roleName = '二级用户';
                                    break;
                            }
                        })
                    }
//                    else {
                    // this.messageError(res.errors[0].message);
//                        this.messageError('系统出错，请稍后!');
//                    }
                } catch (e) {
                    this.messageError();
                }


            },
            async untiedAccount(item) {
                let form = {
                    "loginuserId": item.loginuserId,
                    "loginuserManageId": item.loginuserManageId,
                    "orginfoId": item.orginfoId,
                    "refId": item.refId,
                    "refType": item.refType,
                    "roleRelLoginuserId": item.roleRelLoginuserId
                }

                try {
                    let res = await this.http('/galaxyLoginuserApi/doGalaxyLoginuserUntie', form, 'POST');
                    console.log(res);
                    if (res.success) {
                        // this.auditAccountList = res.data.approvalLoginuserRspDtoPageList.data;
                        // this.loginAccountList = res.data.loginuserManagePageDtoPageList.data;
                        this.$message({
                            message: '解绑成功',
                            type: 'success'
                        })
                        this.getList();
                    } else {
                        this.messageError(res.errors[0].message);
                    }
                } catch (e) {
                    this.messageError();
                }
            },
            untiedAccountShow(item) {
                this.$confirm('确定解绑账号吗？', '', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.untiedAccount(item);
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消操作'
                    });
                });
            },
            handleTablePageNumberChange(val) {
                this.subForm.currentPage = val;
                this.getList();
            },
            async approvalPass(item, type) {
                let form = {
                    "approvalId": item.approvalId,
                    "approvalType": item.approvalType,
                    "id": item.id,
                    "loginuserId": item.loginuserId,
                    "orginfoId": item.orginfoId,
                    source: '0'
                }
                switch (parseInt(item.approvalType)) {
                    case 1:
                        form.roleName = '供应商' + type;
                        break;
                    case 2:
                        form.roleName = '运营商' + type;
                        break;
                    case 3:
                        form.roleName = '一级用户' + type;
                        break;
                    case 4:
                        form.roleName = '二级用户' + type;
                        break;
                }
                try {
                    let res = await this.http('/approvalLoginuser/doGalaxydoLoginuserApprovalPass', form, 'POST');
                    console.log(res);
                    if (res.success) {
                        // this.auditAccountList = res.data.approvalLoginuserRspDtoPageList.data;
                        // this.loginAccountList = res.data.loginuserManagePageDtoPageList.data;
                        this.$message({
                            message: '成功通过',
                            type: 'success'
                        })
                        this.getList();
                    } else {
                        this.messageError('系统出错，请稍后!');
                    }
                } catch (e) {
                    this.messageError();
                }


            },
            approvalPassShow(item, type) {
                this.$confirm('确定通过申请并设为' + type + '吗？', '', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.approvalPass(item, type);
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消操作'
                    });
                });
            },
            async approvalRefuse(item, val) {
                let form = {
                    "approvalId": item.approvalId,
                    "rejectContent": val,
                    approvalType: item.approvalType,
                    "id": item.id,
                    "loginuserId": item.loginuserId,
                    "orginfoId": item.orginfoId,
                    source: '0'
                }

                try {
                    let res = await this.http('/approvalLoginuser/doGalaxydoLoginuserApprovalPassReject', form, 'POST');
                    console.log(res);
                    if (res.success) {
                        // this.auditAccountList = res.data.approvalLoginuserRspDtoPageList.data;
                        // this.loginAccountList = res.data.loginuserManagePageDtoPageList.data;
                        this.$message({
                            message: '拒绝操作成功',
                            type: 'success'
                        })
                        this.getList();
                    }
//                    else {
                    // this.messageError(res.errors[0].message);
//                        this.messageError('系统出错，请稍后!');
//                    }
                } catch (e) {
                    this.messageError();
                }


            },
            approvalRefuseShow(item) {
                this.$prompt('', '请输入拒绝通过的原因：', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputPlaceholder: '如哪项内容填写错误，或哪项内容不符合要求等…',
                    inputType: 'textarea'
                }).then(({value}) => {
                    if (!value) return this.messageError('请输入拒绝理由');
                    this.approvalRefuse(item, value);
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消操作'
                    });
                });
            }
        },
        //监听模版变量
        watch: {}

    }
</script>

